<template>
    <div class="animated fadeIn background" @click="goToHome">
        <div class="wrapper">
            <!-- <img src="/images/banner_splash.jpg" style="width: 100%" /> -->
            <div style="margin-top: 80px">
                <div v-if="isLoading">
                    <b-spinner variant="light" />
                </div>
                <div v-else-if="errorMessage != ''" class="text-center">
                    <div v-html="errorMessage">
                    </div>
                    <div class="mt-2">
                        <b-button class="mr-2" variant="danger" @click="leave">
                            Exit
                        </b-button>
                        <b-button variant="primary" @click="login">
                            Retry
                        </b-button>
                    </div>
                </div>
                <div v-else>
                    <h4 style="margin: 0; font-weight: normal">Welcome,</h4>
                    <div>
                        <strong>{{ fullname }}</strong>
                    </div>
                    <div class="small text-muted mt-4">
                        Tap anywhere to continue
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isReadyToPlay == true" class="version-tag text-light small">
            v{{ version }}
        </div>
    </div>
</template>

<script>
import helper from '../helper';

export default {
    name: 'landing-page',
    data() {
        return {

            isLoading: true,
            fullname: "",
            errorMessage: "",
            version: "1.2.2",

            isReadyToPlay: false

        }
    },
    methods: {

        login() {

            this.isLoading = true
            let token = this.$route.query.token;

            if(token == "NULL_TOKEN" || helper.isNullOrEmpty(token)) {
                this.errorMessage = "Unable to get your user account. Please exit the application and try again!"
                this.isLoading = false
                return
            }

            this.$axios.post("api/v1/holychest/get-user", [], {
                "headers": {
                    "Authorization": "Bearer " + token,
                }
            }).then(res => {
                this.fullname = res.data.data.user.first_name + " " + res.data.data.user.last_name;
                this.version = res.data.data.version

                if(res.data.version != localStorage.getItem("version")) {
                    localStorage.setItem("force-refresh", "true")
                }

                localStorage.setItem("point", res.data.data.user.point)
                localStorage.setItem("version", this.version)
                localStorage.setItem('token', token)
                this.$axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token')
                this.isReadyToPlay = true

            })
            .catch(error => {
                this.errorMessage = helper.handleErrorMessage(error)
            })
            .finally(() => this.isLoading = false)
        },

        goToHome() {
            if(this.isReadyToPlay == true) {
                this.$router.replace({ path: "/home" })
            }
        },

        leave() {
            window.location = "https://pintukeluar"
        }

    },
    created() {
        this.login()
    }
}
</script>

<style scoped>

    .logo {
        width: 50%;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
    }

    .wrapper {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        text-align: center;
        color: white;
    }

    .background {
        height: 100%;
        background: url("/images/landing_page_bg_v4.jpeg");
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .version-tag {
        position: absolute;
        right: 8px;
        bottom: 4px;
    }

</style>
